<template>
    <div class="cart">
        <section class="first-section pb-0" id="cart">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 text-center">
                        <h1 class="mt-100">{{ $t("Kosár") }}</h1>
                    </div>
                </div>
                <div class="row mt-100 d-none d-md-flex">
                    <div class="col-md-7">
                        {{ $t("Termékek") }}
                    </div>
                    <div class="col-md-3 text-end">
                        {{ $t("Mennyiség (csomag)") }}
                    </div>
                    <div class="col-md-2 text-end">
                        {{ $t("Ár / csomag") }}
                    </div>
                </div>
                <div class="cart">
                    <div
                        class="row align-items-center border-bottom py-5"
                        v-for="(item, index) in cart.items"
                        v-bind:key="'cart-checkout-item-' + index"
                    >
                        <div class="col-md-2">
                            <StickerPreview
                                v-if="Object.keys(item).length > 0"
                                :model="item"
                                :background="item.background"
                                :sample="item.sample"
                                :sticker="item.sticker"
                            />
                        </div>
                        <div class="col-md-5 mb-3 mb-md-0">
                            <div class="product-name">
                                {{ item.sticker.name }}
                            </div>
                            <div
                                class="product-usage small-text"
                                v-if="item.material === 1"
                            >
                                {{ $t("Műanyag felületekre") }}
                            </div>
                            <div
                                class="product-usage small-text"
                                v-if="item.material === 2"
                            >
                                {{ $t("Textil felületekre") }}
                            </div>
                            <div class="product-package small-text">
                                {{ $t("30db / csomag") }}
                            </div>
                        </div>
                        <div class="col-8 col-md-3 text-md-end">
                            <div class="number-input-holder d-inline-flex">
                                <button
                                    class="btn btn-simple sub"
                                    v-on:click="removeQty(item)"
                                >
                                    -
                                </button>
                                <div class="product-quantity">
                                    <input
                                        v-model="item.quantity"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        v-on:keyup="
                                            updateQuantity(
                                                item.id,
                                                item.quantity
                                            )
                                        "
                                    />
                                </div>
                                <button
                                    class="btn btn-simple add"
                                    v-on:click="addQty(item)"
                                >
                                    +
                                </button>
                            </div>
                            <DeleteCart
                                :id="item.id"
                                div-class="delete-item d-inline-flex mx-3"
                            />
                        </div>
                        <div class="col-4 col-md-2 text-end">
                            {{ item.total }} Ft
                        </div>
                    </div>
                    <div class="row align-items-center border-bottom py-5">
                        <div class="col-8 col-md-10">
                            <div class="text-green">{{ $t("Összesen") }}</div>
                        </div>
                        <div class="col-4 col-md-2 text-end">
                            <div class="text-green">
                                {{ cart.order_total - cart.shipment_total }} Ft
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="checkout">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div class="h2 text-center mb-100">
                            {{ $t("Szállítási adatok és fizetés") }}
                        </div>
                        <form v-on:submit.prevent="startOrder">
                            <div class="form-check mb-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    v-model="cart.is_company"
                                    id="switchCompany"
                                />
                                <label
                                    class="form-check-label"
                                    for="switchCompany"
                                >
                                    {{ $t("Cégként vásárolok") }}
                                </label>
                            </div>

                            <div class="personal-data">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div
                                            class="form-floating mb-4"
                                            id="lastname"
                                        >
                                            <input
                                                type="text"
                                                name="lastname"
                                                class="form-control mb-4"
                                                :placeholder="$t('Vezetéknév')"
                                                v-model="cart.shipping_lastname"
                                            />
                                            <label for="lastname">{{
                                                $t("Vezetéknév")
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div
                                            class="form-floating mb-4"
                                            id="firstname"
                                        >
                                            <input
                                                type="text"
                                                name="firstname"
                                                class="form-control mb-4"
                                                :placeholder="$t('Keresztnév')"
                                                v-model="
                                                    cart.shipping_firstname
                                                "
                                            />
                                            <label for="firstname">{{
                                                $t("Keresztnév")
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="company-data"
                                v-bind:class="{
                                    'd-none':
                                        !cart.is_company ||
                                        parseInt(cart.is_company) === 0
                                }"
                            >
                                <div
                                    class="form-floating mb-4"
                                    id="company_name"
                                >
                                    <input
                                        type="text"
                                        name="company"
                                        class="form-control mb-4"
                                        :placeholder="$t('Cégnév')"
                                        v-model="cart.company_name"
                                    />
                                    <label for="company_name">{{
                                        $t("Cégnév")
                                    }}</label>
                                </div>

                                <div class="form-floating mb-4" id="tax_number">
                                    <input
                                        type="text"
                                        name="tax_number"
                                        class="form-control mb-4"
                                        :placeholder="$t('Adószám')"
                                        v-model="cart.tax_number"
                                    />
                                    <label for="tax_number">{{
                                        $t("Adószám")
                                    }}</label>
                                </div>
                            </div>

                            <div
                                class="form-floating mb-4"
                                id="shipping_country"
                            >
                                <input
                                    type="text"
                                    name="shipping_country"
                                    class="form-control mb-4"
                                    :placeholder="$t('Ország')"
                                    v-model="cart.shipping_country"
                                />
                                <label for="shipping_country">{{
                                    $t("Ország")
                                }}</label>
                            </div>

                            <div class="row">
                                <div class="col-xl-6">
                                    <div
                                        class="form-floating mb-4"
                                        id="shipping_zip"
                                    >
                                        <input
                                            type="text"
                                            name="shipping_zip"
                                            class="form-control mb-4"
                                            :placeholder="$t('Irányítószám')"
                                            v-model="cart.shipping_zip"
                                        />
                                        <label for="shipping_zip">{{
                                            $t("Irányítószám")
                                        }}</label>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div
                                        class="form-floating mb-4"
                                        id="shipping_city"
                                    >
                                        <input
                                            type="text"
                                            name="shipping_city"
                                            class="form-control mb-4"
                                            :placeholder="$t('Város')"
                                            v-model="cart.shipping_city"
                                        />
                                        <label for="shipping_city">{{
                                            $t("Város")
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-floating mb-4"
                                id="shipping_address"
                            >
                                <input
                                    type="text"
                                    name="shipping_address"
                                    class="form-control mb-4"
                                    :placeholder="$t('Utca, házszám')"
                                    v-model="cart.shipping_address"
                                />
                                <label for="shipping_address">{{
                                    $t("Utca, házszám")
                                }}</label>
                            </div>

                            <div class="form-floating mb-4" id="checkout_email">
                                <input
                                    type="email"
                                    name="email"
                                    class="form-control mb-4"
                                    :placeholder="$t('E-mail')"
                                    v-model="cart.email"
                                />
                                <label for="checkout_email">{{
                                    $t("E-mail")
                                }}</label>
                            </div>

                            <div class="form-floating mb-4" id="checkout_phone">
                                <input
                                    type="tel"
                                    name="phone"
                                    class="form-control mb-4"
                                    :placeholder="$t('Telefonszám')"
                                    v-model="cart.phone"
                                />
                                <label for="checkout_phone">{{
                                    $t("Telefonszám")
                                }}</label>
                            </div>

                            <div class="form-floating">
                                <textarea
                                    class="form-control"
                                    :placeholder="$t('Megjegyzés')"
                                    id="checkout_note"
                                    style="height: 100px"
                                    v-model="cart.note"
                                ></textarea>
                                <label for="checkout_note">{{
                                    $t("Megjegyzés")
                                }}</label>
                            </div>

                            <div class="form-check py-4 mb-4 border-bottom">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    id="shippingbilling"
                                    v-on:change="sameShipBill"
                                    v-model="cart.shipping_same_billing"
                                />
                                <label
                                    class="form-check-label"
                                    for="shippingbilling"
                                >
                                    {{
                                        $t(
                                            "A számlázási adataim megegyeznek a szállítási adataimmal"
                                        )
                                    }}
                                </label>
                            </div>

                            <div
                                class="billing-data py-4 mb-4 border-bottom"
                                v-bind:class="{
                                    'd-none': cart.shipping_same_billing
                                }"
                            >
                                <div>{{ $t("Számlázási adatok") }}</div>
                                <div class="personal-data">
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div
                                                class="form-floating mb-4"
                                                id="billing_lastname"
                                            >
                                                <input
                                                    type="text"
                                                    name="billing_lastname"
                                                    class="form-control mb-4"
                                                    :placeholder="
                                                        $t('Vezetéknév')
                                                    "
                                                    v-model="
                                                        cart.billing_lastname
                                                    "
                                                />
                                                <label for="billing_lastname">{{
                                                    $t("Vezetéknév")
                                                }}</label>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div
                                                class="form-floating mb-4"
                                                id="billing_firstname"
                                            >
                                                <input
                                                    type="text"
                                                    name="billing_firstname"
                                                    class="form-control mb-4"
                                                    :placeholder="
                                                        $t('Keresztnév')
                                                    "
                                                    v-model="
                                                        cart.billing_firstname
                                                    "
                                                />
                                                <label
                                                    for="billing_firstname"
                                                    >{{
                                                        $t("Keresztnév")
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="form-floating mb-4"
                                    id="billing_country"
                                >
                                    <input
                                        type="text"
                                        name="billing_country"
                                        class="form-control mb-4"
                                        :placeholder="$t('Ország')"
                                        v-model="cart.billing_country"
                                    />
                                    <label for="billing_country">{{
                                        $t("Ország")
                                    }}</label>
                                </div>

                                <div class="row">
                                    <div class="col-xl-6">
                                        <div
                                            class="form-floating mb-4"
                                            id="billing_zip"
                                        >
                                            <input
                                                type="text"
                                                name="billing_zip"
                                                class="form-control mb-4"
                                                :placeholder="
                                                    $t('Irányítószám')
                                                "
                                                v-model="cart.billing_zip"
                                            />
                                            <label for="billing_zip">{{
                                                $t("Irányítószám")
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div
                                            class="form-floating mb-4"
                                            id="billing_city"
                                        >
                                            <input
                                                type="text"
                                                name="billing_city"
                                                class="form-control mb-4"
                                                :placeholder="$t('Város')"
                                                v-model="cart.billing_city"
                                            />
                                            <label for="billing_city">{{
                                                $t("Város")
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="form-floating mb-4"
                                    id="billing_address"
                                >
                                    <input
                                        type="text"
                                        name="billing_address"
                                        class="form-control mb-4"
                                        :placeholder="$t('Utca, házszám')"
                                        v-model="cart.billing_address"
                                    />
                                    <label for="billing_address">{{
                                        $t("Utca, házszám")
                                    }}</label>
                                </div>
                            </div>
                            <div class="row align-items-center mb-4">
                                <div class="col-10">
                                    <div class="form-floating">
                                        <select
                                            class="form-select"
                                            id="payment_method_id"
                                            v-model="cart.payment_method_id"
                                        >
                                            <option
                                                v-for="(item,
                                                index) in payments"
                                                v-bind:key="'payment-' + index"
                                                :value="item.id"
                                                >{{ item.name }}</option
                                            >
                                        </select>
                                        <label for="payment_method_id">{{
                                            $t("Fizetési mód kiválasztása")
                                        }}</label>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <img
                                        src="/img/simplepay.png"
                                        class="w-100"
                                    />
                                </div>
                            </div>
                            <div class="form-floating mt-4">
                                <select
                                    class="form-select"
                                    id="shipment_method_id"
                                    v-model="cart.shipment_method_id"
                                >
                                    <option
                                        v-for="(item, index) in shipments"
                                        v-bind:key="'shipment-' + index"
                                        :value="item.id"
                                        >{{ item.name }}</option
                                    >
                                </select>
                                <label for="shipment_method_id">{{
                                    $t("Szállítási mód kiválasztása")
                                }}</label>
                            </div>

                            <div class="row align-items-center py-5">
                                <div class="col-8 col-md-10">
                                    <div>{{ $t("Szállítási költség") }}</div>
                                    <div class="text-green">
                                        {{ $t("Összesen") }}
                                    </div>
                                </div>
                                <div class="col-4 col-md-2 text-end">
                                    <div>{{ cart.shipment_total }} Ft</div>
                                    <div class="text-green">
                                        {{ cart.order_total }} Ft
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 offset-xl-2">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value="1"
                                            v-model="cart.adatvedelem"
                                            id="policy"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="policy"
                                        >
                                            <small
                                                >{{
                                                    $t(
                                                        "Elolvastam és elfogadom az"
                                                    )
                                                }}
                                                <router-link
                                                    to="/adatvedelem"
                                                    target="_blank"
                                                    >{{
                                                        $t(
                                                            "Adatvédelmi Szabályzatot"
                                                        )
                                                    }}</router-link
                                                >.</small
                                            >
                                        </label>
                                    </div>
                                    <div class="text-center mt-5">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                        >
                                            {{ $t("Tovább a fizetéshez") }}
                                        </button>
                                    </div>
                                    <div class="text-center mt-5 small-text">
                                        {{
                                            $t(
                                                "Tudomásul veszem, hogy a(z) Névmatrica (2800 Tatabánya, Sárberek 302. 10/44.) adatkezelő által a(z) nevmatrica.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft. (1093 Budapest, Közraktár u. 30-32.), mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: Vásárló neve. Az adatfeldolgozóáltal végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:"
                                            )
                                        }}
                                        <a
                                            href="http://simplepay.hu/vasarlo-aff"
                                            target="_blank"
                                            >http://simplepay.hu/vasarlo-aff</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <b-modal id="loginModal" ref="login-modal" size="xl" hide-footer>
            <LoginModal v-on:closeModal="closeModal" />
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteCart from "../components/cart/DeleteCart";
import StickerPreview from "../components/cart/StickerPreview";
import LoginModal from "../components/cart/LoginModal";

export default {
    name: "Cart",
    data() {
        return {
            cart: {
                items: [],
                shipping_same_billing: true,
                shipment_method_id: null,
                payment_method_id: null
            },
            payments: [],
            shipments: []
        };
    },
    components: { DeleteCart, StickerPreview, LoginModal },
    beforeMount() {
        const self = this;
        if (self.isAuthenticated) {
            self.getCartAuth();
        } else {
            self.getCart();
        }

        self.$eventHub.$on("updateCart", function() {
            console.log("cart-update");
            if (self.isAuthenticated) {
                self.getCartAuth();
            } else {
                self.getCart();
            }
        });
        self.getPaymentMethods();
        self.getShipmentMethods();
    },
    mounted() {
        if (!this.isAuthenticated) {
            this.$refs["login-modal"].show();
        }
    },
    methods: {
        removeQty(item) {
            if (item.quantity > 0) {
                item.quantity -= 1;
            }
            this.updateQuantity(item.id, item.quantity);
        },
        addQty(item) {
            item.quantity += 1;
            this.updateQuantity(item.id, item.quantity);
        },
        updateShipmentPrice(id) {
            const sh = this.shipments.find(element => element.id === id);
            if (sh && sh.price) {
                this.cart.shipment_total = sh.price;
            }
        },
        setDefaults() {
            if (this.payments && this.payments.length > 0) {
                this.cart.payment_method_id = this.payments[0].id;
            }
            if (this.shipments && this.shipments.length > 0) {
                this.cart.shipment_method_id = this.shipments[0].id;
            }
            this.cart.shipping_same_billing = true;
        },
        updateQuantity(id, quantity) {
            if (!quantity || quantity < 1) {
                return false;
            }

            this.cartUpdate(id, quantity, this.cart.shipment_method_id);
        },
        cartUpdate(id, quantity, shipment_method_id) {
            const self = this;
            const cartUUid = localStorage.getItem("cart_uuid");
            self.$http
                .post(`${self.url}/cart/${cartUUid}`, {
                    id: id,
                    quantity: quantity,
                    shipment_method_id: shipment_method_id
                })
                .then(response => {
                    self.cart.items = response.data.data.items;
                    self.cart.order_total = response.data.data.order_total;
                    self.cart.shipment_total =
                        response.data.data.shipment_total;
                    self.$eventHub.$emit("updateCart", true);
                });
        },
        sameShipBill() {
            this.cart.billing_lastname = this.cart.shipping_lastname;
            this.cart.billing_firstname = this.cart.shipping_firstname;
            this.cart.billing_country = this.cart.shipping_country;
            this.cart.billing_city = this.cart.shipping_city;
            this.cart.billing_zip = this.cart.shipping_zip;
            this.cart.billing_address = this.cart.shipping_address;
        },
        closeModal() {
            this.$refs["login-modal"].hide();
        },
        getCart() {
            const self = this;
            const cartUUid = localStorage.getItem("cart_uuid");
            self.$http
                .get(`${self.url}/cart/${cartUUid}`)
                .then(response => {
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                    self.cart = response.data.data;
                    if (!self.cart.shipping_same_billing) {
                        self.cart.shipping_same_billing = true;
                    }
                })
                .finally(() => {
                    self.setDefaults();
                });
        },
        getCartAuth() {
            const self = this;
            const cartUUid = localStorage.getItem("cart_uuid");
            self.$http
                .get(`${self.url}/cart/auth/${cartUUid}`)
                .then(response => {
                    localStorage.setItem("cart_uuid", response.data.data.uuid);
                    self.cart = response.data.data;
                })
                .finally(() => {
                    self.setDefaults();
                });
        },
        getPaymentMethods() {
            const self = this;
            self.$http.get(`${self.url}/payment-methods`).then(response => {
                self.payments = response.data.data;
                self.cart.payment_method_id = self.payments[0].id;
            });
        },
        getShipmentMethods() {
            const self = this;
            self.$http.get(`${self.url}/shipment-methods`).then(response => {
                self.shipments = response.data.data;
                self.cart.shipment_method_id = self.shipments[0].id;
            });
        },
        startOrder() {
            if (this.cart.shipping_same_billing) {
                this.sameShipBill();
            }
            const self = this;
            self.$http.post(`${this.url}/order`, this.cart).then(response => {
                localStorage.setItem("cart_uuid", response.data.uuid);
                if (response.data.redirect) {
                    document.location.href = response.data.redirect;
                }
            });
        }
    },
    computed: {
        ...mapGetters(["currentUser", "isAuthenticated"]),
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    watch: {
        isAuthenticated: function(val) {
            if (val) {
                this.getCartAuth();
            } else {
                this.getCart();
            }
        },
        "cart.shipment_method_id": function(val) {
            this.updateShipmentPrice(val);
            this.cartUpdate(null, 0, val);
        }
    }
};
</script>

<style scoped></style>
