<template>
    <div class="row">
        <div class="col-xl-6 border-end">
            <div class="p-4">
                <div class="h4 mb-3">{{ $t("Visszatérő vásárló") }}</div>
                <form v-on:submit.prevent="doLogin">
                    <div class="form-floating mb-2" id="modalEmail">
                        <input
                            type="email"
                            name="email"
                            class="form-control mb-2"
                            placeholder="E-mail"
                            v-model="form.email"
                        />
                        <label for="modalEmail">{{ $t("E-mail") }}</label>
                    </div>
                    <div class="form-floating mb-2" id="modalPassword">
                        <input
                            type="password"
                            name="password"
                            class="form-control mb-2"
                            :placeholder="$t('Jelszó')"
                            v-model="form.password"
                        />
                        <label for="modalPassword">{{ $t("Jelszó") }}</label>
                    </div>

                    <button type="submit" class="btn btn-primary w-100">
                        {{ $t("Belépés") }}
                    </button>
                    <a
                        href="#"
                        class="small-text d-block my-3"
                        data-bs-toggle="modal"
                        data-bs-target="#forgotPassword"
                        data-bs-dismiss="modal"
                        >{{ $t("Elfelejtettem a jelszavamat.") }}</a
                    >
                </form>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="p-4">
                <div class="h4 mb-3">{{ $t("Új vásárló?") }}</div>
                <p>{{ $t("A regisztráció előnyei") }}:</p>
                • {{ $t("Gyorsabb vásárlás") }} <br />
                • {{ $t("Rendeléseid nyomokövetése") }} <br />
                • {{ $t("Exkluzív ajánlatok") }}

                <router-link
                    :to="{ name: 'Registration' }"
                    class="btn btn-primary mb-2 mt-4 w-100"
                    >{{ $t("Regisztráció") }}</router-link
                >
                <a
                    href="#"
                    v-on:click.prevent="closeModal"
                    class="btn btn-secondary w-100"
                    data-bs-dismiss="modal"
                    >{{ $t("Regisztráció nélküli vásárlás") }}</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";

export default {
    name: "LoginModal",
    data() {
        return {
            form: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal", true);
        },
        doLogin() {
            const self = this;
            this.$http.post(this.url + "/login", this.form).then(response => {
                $cookies.set(
                    "id_token",
                    response.data.token,
                    60 * 60 * 24 * 1000
                );
                Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
                    JwtService.saveToken(JwtService.getToken());
                });
                self.loggedOutModal = false;
                self.loggedInModal = false;
                self.$emit("closeModal", true);
            });
        }
    }
};
</script>

<style scoped></style>
